import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { StaticImage } from "gatsby-plugin-image"

library.add(fas)

const WebSites = () => {
  return (
    <>
      <Navbar variant="dark">
        <Nav className="flex-column text-uppercase">
          <Nav.Link
            href="https://www.b17.ru/stepanenkoap/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={["fas", "link"]} size="lg" />
            <span className="pl-3">B17 Психологи</span>
          </Nav.Link>
          <div style={{ paddingTop: "20px" }}>
            <a
              href="https://www.b17.ru/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ padding: "10px" }}
            >
              <StaticImage
                src="../images/b17.png"
                quality={70}
                backgroundColor="#051f34"
                loading="eager"
                width={100}
                height={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="b17.ru Сайт профессиональных психологов – психологическая помощь и онлайн консультации"
              />
            </a>
            <a
              href="https://beget.com/?id=1127859"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../images/beget.png"
                quality={70}
                backgroundColor="#051f34"
                loading="eager"
                width={100}
                height={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Beget хостинг"
              />
            </a>
          </div>
        </Nav>
      </Navbar>
    </>
  )
}

export default WebSites
