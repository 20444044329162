import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"

library.add(fas)
library.add(fab)

const ContactButtons = ({ email, whatsapp, skype }) => {
  return (
    <Navbar variant="dark">
      <Nav className="flex-column text-uppercase">
        <div className="nav-link" to="/contacts">
          <FontAwesomeIcon
            style={{ fontSize: "20px" }}
            icon={["fas", "envelope"]}
            size="lg"
          />
          <span className="pl-3 ltrTextReverse">{email}</span>
        </div>
        <Nav.Link href={whatsapp} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={["fab", "whatsapp-square"]} size="lg" />
          <span className="pl-3">Связаться через WhatsApp</span>
        </Nav.Link>
        <Nav.Link href={skype} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={["fab", "skype"]} size="lg" />
          <span className="pl-3">Связаться через Skype</span>
        </Nav.Link>
      </Nav>
    </Navbar>
  )
}

export default ContactButtons
