import React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { useStaticQuery, graphql } from "gatsby"

library.add(fab)

const SocialButtons = () => {
  const data = useStaticQuery(graphql`
    {
      sanityContacts {
        facebook
        instagram
        youtube
      }
    }
  `)
  const { facebook, instagram, youtube } = data.sanityContacts
  return (
    <Navbar variant="light">
      <Nav className="flex-row">
        <Nav.Link
          href={instagram}
          target="_blank"
          className="pr-3"
          rel="noopener noreferrer"
          data-rb-event-key={instagram}
        >
          <FontAwesomeIcon icon={["fab", "instagram"]} size="lg" />
        </Nav.Link>
        <Nav.Link
          href={facebook}
          target="_blank"
          className="pr-3"
          rel="noopener noreferrer"
          data-rb-event-key={facebook}
        >
          <FontAwesomeIcon icon={["fab", "facebook"]} size="lg" />
        </Nav.Link>
        <Nav.Link
          href={youtube}
          target="_blank"
          rel="noopener noreferrer"
          data-rb-event-key={youtube}
        >
          <FontAwesomeIcon icon={["fab", "youtube"]} size="lg" />
        </Nav.Link>
      </Nav>
    </Navbar>
  )
}

export default SocialButtons
