import React from "react"
import { Link } from "gatsby"
import { Navbar, Nav } from "react-bootstrap"

const Mainmenu = () => {
  return (
    <Navbar variant="dark">
      <Nav className="flex-column text-uppercase">
        <Link to="/" className="nav-link">
          Домой
        </Link>
        <Link to="/obo-mne" className="nav-link">
          Обо мне
        </Link>
        <Link to="/video" className="nav-link">
          Видео
        </Link>
        <Link to="/contacts" className="nav-link">
          Контакты
        </Link>
        <Link to="/faq" className="nav-link">
          ЧаВо
        </Link>
      </Nav>
    </Navbar>
  )
}
export default Mainmenu
