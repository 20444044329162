import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col, Navbar } from "react-bootstrap"
import Mainmenu from "../components/mainmenu"
import ContactButtons from "../components/contactButtons"
import SocialButtons from "../components/socialButtons"
import WebSites from "../components/websites"
import { Link } from "gatsby"

const Footer = ({ siteTitle, siteCreator, siteCreatorMessage }) => {
  const data = useStaticQuery(graphql`
    {
      sanityContacts {
        email
        whatsapp
        skype
      }
    }
  `)
  const { email, whatsapp, skype } = data.sanityContacts

  return (
    <footer className="bg-secondary">
      <Container fluid="lg">
        <Row className="p-5">
          <Col lg="4" xs="12" md="6" sm="12">
            <p className="text-white display-5 uk-heading-divider">Меню</p>
            <Navbar variant="dark">
              <Mainmenu />
            </Navbar>
          </Col>
          <Col>
            <p className="text-white display-5 uk-heading-divider d-none d-md-block">
              Контакты
            </p>
            <Navbar variant="dark">
              <ContactButtons email={email} whatsapp={whatsapp} skype={skype} />
            </Navbar>
          </Col>
          <Col>
            <p className="text-white display-5 uk-heading-divider">Соцсети</p>
            <Navbar variant="dark">
              <SocialButtons />
            </Navbar>
            <Navbar variant="dark">
              <WebSites />
            </Navbar>
          </Col>
        </Row>
        <Row>
          <Col className="text-left p-5">
            <p className="text-light">
              <Link to="/policy">
                Политика в отношении обработки персональных данных
              </Link>
            </p>
            <p className="text-light">
              {siteTitle} © {new Date().getFullYear()}, Все права защищены.
              Психолог в Барселоне. Александр Степаненко
            </p>
            <a
              href={siteCreator}
              target="_blank"
              className="text-light"
              rel="noopener noreferrer"
            >
              {siteCreatorMessage}
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
export default Footer
