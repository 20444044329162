import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import { Container, Navbar, Nav } from "react-bootstrap"
import SocialButtons from "../components/socialButtons"
import checkIco from "../images/tick.svg"

const Header = ({ siteTitle }) => {
  return (
    <header className="bg-white">
      <Container fluid="lg" style={{ overflow: "hidden" }}>
        <Navbar bg="white" sticky="top" variant="light" expand="md">
          <Nav className="p-3">
            <Link to="/" className="nounderline">
              <img
                src={checkIco}
                alt="Sober Expert - психолог в Барселоне. Проект Александра Степаненко"
                style={{ width: "50px" }}
              />
              <span className="logo text-secondary pl-3">Sober Expert</span>
            </Link>
          </Nav>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto text-uppercase px-5">
              <Link to="/" className="nav-link">
                Главная
              </Link>
              <Link to="/obo-mne" className="nav-link">
                Обо&nbsp;мне
              </Link>
              <Link to="/video" className="nav-link">
                Видео
              </Link>
              <Link to="/contacts" className="nav-link">
                Контакты
              </Link>
            </Nav>
            <SocialButtons />
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
